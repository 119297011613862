<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Schools (SchoolPay enabled enroller)</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Schools
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      v-on:keyup.enter="searchAccountHolder"
                      @input="search.info = $event !== null ? $event : ''"
                      label="Lookup by name, email or phone"
                      v-model="search.info" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :md="currentUser.access_type === 'score' ? 3 : 2">
                  <v-text-field
                      v-on:keyup.enter="searchAccountHolder"
                      @input="search.mobile = $event !== null ? $event : ''"
                      label="Phone or mobile"
                      v-model="search.mobile" outlined dense clearable
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!=='score'">
                  <v-select
                      label="SCORE"
                      v-model="search.current_score_id"
                      v-on:keyup.enter="searchAccountHolder"
                      @input="search.current_score_id = $event !== null ? $event : ''"
                      item-text="name"
                      item-value="id"
                      :items="scores"
                      outlined
                      dense
                      clearable
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      v-on:keyup.enter="searchAccountHolder"
                      dense clearable
                  ></v-select>
                </v-col>
                
                <v-col class="text-right" cols="12" sm="6" :md="currentUser.access_type === 'score' ? 4 : 2">
                  <v-btn :loading="isLoading"
                         @click="searchAccountHolder"
                         class="btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

              </div>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table"  v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">School</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Surname</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">First name</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">UniqueID </th>
                  <th class="px-3">Email</th>
                  <th class="px-3">Phone</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">SCORE</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="accountHolders.length > 0">
                  <tr  v-for="(item, index) in accountHolders" :key="index">
                    <td style="max-width: 270px !important; white-space: pre-wrap;">
                      {{item.school_name }}

                      <div>
                        <span class="badge" :class="item.is_active ? 'badge-primary' : 'badge-danger' ">
                          {{ item.status_text }}
                        </span>
                      </div>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">

                      <a @click="editAccountHolder(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.last_name }}
                      </a>

                      <div>
<!--                        <span class="badge badge-role mr-1 " v-if="item.role_text">-->
<!--                          {{ item.role_text}}-->
<!--                        </span>-->
<!--                        <span class="badge badge-success"-->
<!--                              v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"-->
<!--                        >{{ item.status_text }}</span>-->
<!--                        <span class="badge badge-info ml-1">-->
<!--                          {{ item.total_candidate}}-->
<!--                        </span>-->
                      </div>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">

                      <span class="text-dark-75 text-hover-primary mb-1" v-copy="item.first_name">
                        {{item.first_name }}
                      </span>
                    </td>

                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;" >
                      <span v-copy="item.unique_identifier">{{item.unique_identifier ? item.unique_identifier : 'N/A' }}</span>
                    </td>
                    <td class="px-3">
                      <span  class="mb-1" :href="'mailto:'+item.email" v-copy="item.email">
                        {{item.email ? item.email : 'N/A' }}
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="mb-1" :href="'tel:'+item.preferred_phone" v-copy="item.preferred_phone">
                        {{item.preferred_phone}}
                      </span>
                    </td>

                    <td style="max-width: 270px !important; white-space: pre-wrap;">
                      <span>
                        {{item.score_short_name ? item.score_short_name : 'N/A' }}
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewApplicationSummary(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-eye"></i>

                                  </span>
                                <span class="navi-text">View application details</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="deactivatedSchool(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-ban"></i>

                                  </span>
                                <span class="navi-text">Unlink school & remove SchoolPay</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="accountHolders.length == 0">
                    <td colspan="7" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="isLoading"
                  v-if="accountHolders.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAccountHolder"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <school-pay-application-detail ref="application-detail" @refresh="getAllAccountHolder" ></school-pay-application-detail>
  </v-app>
</template>

<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import SchoolPayApplicationDetail from "@/view/pages/view/school/pay/ApplicationDetail";
const accountHolder=new AccountholderService();
const score = new ScoreService();
export default {
  components: {SchoolPayApplicationDetail},
  name: "Index",
  data(){
    return{
      isLoading:false,
      scores: [],
      accountHolders: [],
      page: null,
      total: null,
      perPage: null,
      search:{
        info:'',
        current_score_id:'',
        type:'',
        mobile: '',
        is_school_account: '1',
        is_active:''
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
    }
  },
  methods:{
    viewApplicationSummary(accountHolderId){
      this.$refs['application-detail'].openDialog(accountHolderId);
    },
    searchAccountHolder(){
      this.getAllAccountHolder();
    },
    getAllAccountHolder(){
      this.isLoading = true;
      accountHolder
          .getSchoolAccount(this.search,this.page)
          .then(response => {
            this.accountHolders = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading = false;
          })
          .catch((err) => {
            this.$snotify.error('Oops looks like something went wrong');
            this.searchFieldDialog=false;
            this.isLoading = false;
          });
    },
    deactivatedSchool(item) {
      this.$confirm({
        message: `Are you sure you want to unlink this school account and disable SchoolPay for this enroller?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_school_account = 0;
            accountHolder.update(item.id, item)
                .then((response) => {
                  this.getAllAccountHolder();
                  this.$snotify.success("Enroller school account unlinked");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    getAllScore() {
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          });
    },
    editAccountHolder(accountHolderId){
      this.$router.push({
        name: "accountholder-edit",
        params: { accountholderId: accountHolderId }
      });
    },
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    this.getAllAccountHolder();
    this.getAllScore();
  }
}
</script>

<style scoped>

</style>